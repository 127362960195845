.App {
  display: flex;

  flex-direction: column;

  text-align: left;
  background-color: rgb(10, 116, 63);
  padding: 40px 10px;
  font-family: "Space Grotesk", sans-serif;
}

.MyApp {
  background: linear-gradient(
    242deg,
    rgba(42, 20, 83, 1) 27%,
    rgba(34, 19, 62, 1) 49%,
    rgba(35, 18, 68, 1) 72%
  ) !important;

  font-family: "Space Grotesk", sans-serif;
}

.AppHeader {
  /* height: 90px; */
  display: flex;
  background-color: black;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 50px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  flex-wrap: wrap;
}

.AppBody {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
  padding-top: 50px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input {
  background: rgba(0, 255, 163, 0.23);
}

.formField {
  margin: 10px 0 10px 0;
  min-width: auto;
  width: fit-content;
  padding: 10px;
  font-size: 16px;
  border: 0;

  background: rgb(255, 255, 255);
  /* border-radius: 12px; */
  border: 2px solid;
  border-radius: 10px;
}
.formButton {
  margin: 10px 0 10px 0;
  padding: 15px;
  font-size: 16px;
  border: 0;

}


.button55 {
  align-items: center;
  background-clip: padding-box;
  background-color: #fa6400;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.button55:hover,
.button55:focus {
  background-color: #fb8332;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.button55:hover {
  transform: translateY(-1px);
}

.button55:active {
  background-color: #c85000;
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}
