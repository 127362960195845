@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");

.main_footer {
  /* padding: 40px 0px 0px 0px; */
  background: #064b28 !important;
  /* background: url(../Assets/fr.png),url(../Assets/fm.png); */
  background-repeat: no-repeat;
  position: relative;
  border-top: 2px solid #000000;
  border-top: 1px solid white;
}
.overy {
  position: absolute; /* Sit on top of the page content */
  /* display: none; Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(
    0,
    51,
    102,
    0.5
  ) !important; /* Black background with opacity */
  z-index: 0;
}

.footer_link {
  text-decoration: none;
  margin: 0px 8px;
  font-size: 36px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: #fdfdfd;
}

.footer_link:hover {
  color: white !important;
}

.cum {
 font-family: "East Sea Dokdo", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 49px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #ffffff;
}
.lower_foss {
  background-color: #0b3b95;
  padding: 20px 10px;
}

.joi {
  font-family: "East Sea Dokdo", sans-serif;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  /* line-height: 20px; */
  letter-spacing: 0em;
  text-align: center;
}

.footer_in {
  background-color: white;
  padding: 10px 20px;
  border: none;
  background: transparent;
}
.footer_in::placeholder {
  font-family: "East Sea Dokdo", sans-serif;
  color: #aaaaaa;
  font-size: 24px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.subs {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding: 10px 20px;
  border: none;

  background: #0b3b95;
  border-radius: 8px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.foss {
  background: white !important;
  padding: 0px;
  border-radius: 8px;
}

.ada {
  margin: 1.4rem 0;
  font-size: 26px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: -0.02em;
  text-align: center;
  color: rgb(233, 254, 214);
}

.pleft_f {
  color: #c4c8d1;

  font-size: 18px;
  margin: 1rem 0;
  width: 90%;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.slkmf {
  display: flex;
  align-items: center;
}

.lower_foss {
  border-top: 1px solid #8890a46e;
  padding-top: 30px;
}

.lowerse a {
  color: #fff;
  font-family: "East Sea Dokdo", sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
  margin: 0 8px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.sklaa {
  text-align: center;
}
.texsdend {
  text-align: end;
}

.twitet_cions {
  font-size: 24px;
  color: #ffffff;
}

.footer_logo {
  width: 100px !important ;
}

@media only screen and (max-width: 600px) {
  .slkmf {
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }
  .texsdend {
    text-align: start;
  }

  .sklaa {
    text-align: start;
  }
  .footer_link {
    margin: 10px 8px !important;
  }
  .cum {
    font-size: 32px;
  }
  .footer_in {
    width: 80%;
  }
}


.footer-font{
  font-family: "Urbanist", sans-serif !important;
  font-size: 20px;
}