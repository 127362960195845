.main_road_map {
  /* background: url(../Assets/b7.png), #c0fff8; */
  background-blend-mode: overlay; 
  background-color: rgb(10, 116, 63);
  /* padding: 40px 10px; */
  padding: 0px 5px 40px ;
  margin-right: 0px;
  
  /* height: 150vh; */
}
.raod_head {
  /* font-family: "Space Grotesk", sans-serif; */
  font-family: "Comic Neue", cursive;
  font-size: 54px;
  /* font-weight: 400; */
  line-height: 54px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #003366;
}
.raod_para {
  /* font-family: " Work Sans"; */
  /* font-family: "Space Grotesk", sans-serif; */
  font-family: "Comic Neue", cursive;
  font-size: 26px;
  color: #ffffff;
  margin: 1.6rem 0;
  font-weight: 500;
  /* line-height: 35px; */
  letter-spacing: 0em;
  text-align: left;
  word-break: break-word;
  /* margin-top: 50px; */
}

@media only screen and (max-width:600px){
  .raod_head {
    font-size: 42px;
    line-height: 30px;
  }
  .raod_para {
    font-size: 25px;
  }
}



.road_line {
  /* border: 4.05px solid; */

  background: linear-gradient(90deg, #e0660b 0%, #393636 17.97%);
  position: relative;

  width: 100%;
  height: 2px;
  margin-top: 3rem;
}
::marker {
  margin-right: 0px !important;
}

.cir {
  width: 15px;
  height: 15px;
  background: linear-gradient(0deg, #e0660b, #e0660b),
    linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
  border-radius: 50%;
  position: absolute;
  top: -7px;
}

.cir2 {
  background: #3d3d3f !important;
  left: 25%;
}

.cir3 {
  left: 51% !important;
}
.question_rod {
  position: absolute;
  top: -35px;
}

.ndq {
  left: 25%;
}

.n3dq {
  left: 50%;
}

.cir4 {
  left: 76% !important;
}

.n4dq {
  left: 75% !important    ;
}
.rod_bt_head {
  /* font-family: "Space Grotesk", sans-serif; */
  font-size: 24px;
  font-weight: 400;
  margin-top: 1.4rem;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #747474;
}
.rod_list {
  color: #393636;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 5px 0px;
}

@media only screen and (max-width: 600px) {
  .main_road_map {
    background-repeat: no-repeat;
    background-size: cover;
  }
  .question_rod {
    position: static !important;
  }
  .presale_box {
    height: auto;
  }

  .mobile-border-only {
    border: 2px solid black;
  }

  .image-buy{
    margin-left: 0px !important;
  }
  .widget{
    font-size: 34px !important;
    font-weight: 800;
    /* margin-bottom: 20px; */
    display: block;
    color: #E9F50B;
  
  }


}

@media only screen and (min-width: 760px) and (max-width: 999px) {
  .main_road_map {
    background-repeat: no-repeat;
    background-size: cover;
  }
  .presale_box {
    height: auto !important;
  }
  .footer_in {
    width: 90%;
  }
  .tb_size {
    width: 20px;
  }
  .image-buy{
    margin-left: 0px !important;
  }
}


.image-buy{
  height: auto;
  width: 47%;
  /* border: 5px solid #E9FED6; */
  border-radius: 30px;
  margin-left: 210px;
}


.widget{
  font-size: 37px;
  font-weight: 800;
  /* margin-bottom: 20px; */
  display: block;
  color: #E9F50B;

}
