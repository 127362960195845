.main_farming {
    padding: 40px 10px ;
    background: #b0ebf8;

}

.farming_head {
  font-family: "East Sea Dokdo", sans-serif;
font-size: 54px;
font-weight: 400;
line-height: 54px;
letter-spacing: 0.02em;
text-align: center;
color: #003366;
}


.farming_para{
  color: #000000;
font-size: 34px;
font-weight: 500;
line-height: 33px;
margin-top: 1rem;
letter-spacing: 0em;
text-align: center;

    
}

@media only screen and (max-width:600px){
  .farming_head {
    font-size: 42px;
    line-height: 30px;
  }
  .farming_para {
    font-size: 40px;
  }
}

.border-image{
  border: 2px solid white;
  transform: rotate(-10deg); /* Adjust the angle as needed */
  display: inline-block;
}

.border-image2{
  border: 2px solid white;
  transform: rotate(5deg); /* Adjust the angle as needed */
  display: inline-block;
  /* margin-left: 10px; */
}
.border-image3{
  border: 2px solid white;
  transform: rotate(10deg); /* Adjust the angle as needed */
  display: inline-block;
  /* margin-left: 10px; */
}
.border-image4{
  border: 2px solid white;
  transform: rotate(-5deg); /* Adjust the angle as needed */
  display: inline-block;
  /* margin-left: 10px; */
}