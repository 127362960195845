.earn_apy_main{
    background-color:  rgb(10, 116, 63);
    /* background-image: url('../Assets/leavs.svg'); */
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    /* transform: rotate(360deg); */

    /* margin-top: 80px; */
    padding: 0px 5px 40px ;
    /* height: 150vh; */
    /* border-bottom: 1px solid white; */
}

.main_epy_heading {
    color: #FFFFFF;
    font-family: Impact;
    font-size: 44px;
    font-weight: 400;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: center;


}

.epy_para {
    /* font-family: Work Sans; */
font-size: 24px;
font-weight: 500;
line-height: 33px;
letter-spacing: 0em;
text-align: center;
color: #FFFFFF;
}
.epy_Stake {
 
padding: 12.5px 24px;
border-radius: 8px;
background: #E0660B;
border: none;
/* font-family: Work Sans; */
font-size: 16px;
font-weight: 500;
line-height: 19px;
letter-spacing: 0px;
text-align: left;
color: #FDFDFD;


}


@media only screen and (max-width:760px){
    .main_epy_heading {
        font-size: 28px;
    }
    .epy_Stake{
        padding: 12px 6px !important;
    }
}
