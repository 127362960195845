



.tokonomics_main {
    position: relative;
    background-image: url(../Assets/king-2.jpeg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 60px 10px;
    font-family: "Space Grotesk", sans-serif;
  }
  
  .tokonomics_main::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.918); /* Adjust transparency here */
    z-index: 1;
  }
  
  .tokonomics_main > * {
    position: relative;
    z-index: 2;
  }





.token_head {
    font-family: "East Sea Dokdo", sans-serif;
font-size: 54px;
font-weight: 400;
line-height: 54px;
letter-spacing: 0.02em;
text-align: center;

color: #000000;
}

.token_box_head{
    font-family: "East Sea Dokdo", sans-serif;
    font-size: 48px;
    font-weight: 400;
    line-height: 54px;
    letter-spacing: 0.02em;
    white-space: nowrap;
    text-align: end;
    color: #000000;

}

.tkn_para {
    font-family: "East Sea Dokdo", sans-serif;
    color: #070707;
    margin: 2rem 0;
    font-size: 44px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center;

}


.para_tkn_main{

    color: #000000;
    margin: 1rem 0;
    font-size: 38px;
    font-weight: 400;
    /* line-height: 33px; */
    letter-spacing: 0em;
    text-align: end;
}
.tkn_le {
    position: absolute;
    left: 0px;
    bottom: 0px;

}
.tkn_right {
    position: absolute;
    right: 0px;
    bottom: 0px;

}

.content_tkn {
    font-family: 'Impact';
    font-size: 44px !important;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #E0660B;
    position: absolute;
}

.tknmain_r {
    position: relative;
    margin: 2rem 0;
}

.ps1 {
    left: -35%;
}
.ps2 {
    right: -0%;
    width: 30%;
}
.ps3 {
    left: 10%;
    bottom: 50px;
    width: 30%;
}
.ps4 {
    left: 35%;
    bottom: -50px;
    width: 30%;
}
.ps5 {
    right: -1%;
    bottom: 100px;
    width: 30%;
}


@media only screen and (max-width:999px){
    .ps1{
        position: static !important;
        width: 100%;
    }
    .ps2{
        position: static !important;
        width: 100%;
    }
    .ps3{
        position: static !important;
        width: 100%;
    }
    .ps4{
        position: static !important;
        width: 100%;
    }
    .ps5{
        position: static !important;
        width: 100%;
    }
    .imgss {
        display: none;
    }


    .token_box_head{
        font-size: 22px !important;
        text-align: start;
        
    }

    .para_tkn_main{
        text-align: center;
    }

    .mobile-textsize{
        font-size: 14px;
    }
}



@media only screen and (max-width:459px){
    .mobile-textsize{
        font-size: 12px;
    }
    .video-container {
        display: flex;
        justify-content: center;
        gap: 20px; /* Adjust the gap as needed */
        flex-direction: column !important;
      }
}

.video-container {
    display: flex;
    justify-content: center;
    gap: 20px; /* Adjust the gap as needed */
    flex-direction: row;
  }
  
  .video-wrapper {
    border: 2px solid white;
    border-radius: 15px; /* Adjust the border radius as needed */
    overflow: hidden;
  }
  
  iframe {
    border: none; /* Remove default iframe border */
    border-radius: 15px; /* Match the border radius */
  }
  