@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");

.site_font {
  font-family: "Space Grotesk", sans-serif;
}
.mian_nav {
  /* background:  rgb(203 255 248); */
  /* background-image: url(../Assets/knig-header.jpg); */
  background-color: rgb(203 255 248);
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  padding: 10px 5px;
  height: 14vh;
}

.main_logo {
  width: 100px;
  font-family: "Space Grotesk", sans-serif;
}
.main_logo img {
  width: 80%;
}
.nav-link {
  font-family: "Space Grotesk", sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 18.77px;
  color: #000000 !important;
  margin: 0px 5px;
  white-space: nowrap;
  padding-bottom: 0px;
}

@media only screen and (max-width: 700px) {
  .nav-link {
    padding-bottom: 20px;
  }
}

.nav-link:hover {
  color: #fdfdfd;
}

.apy {
  color: #fbb62f !important;
}
.outline_btn_ora {
  border: 2px solid #e0660b;
  color: #fdfdfd;

  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;

  padding: 12px 24px;
  border-radius: 8px;
  background: transparent;
}

.header_btn_ora {
  color: #fdfdfd;

  font-size: 26px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  border: none;

  padding: 12px 24px;
  border-radius: 5px;
  background: #000000;
}

@media only screen and (max-width: 600px) {
  .nav-link {
    text-align: center;
  }
  .outline_btn_ora {
    width: 100%;
    text-align: center;
  }
  .header_btn_ora {
    width: 100%;
  }
  .main_play_nd_g {
    width: 100%;
    height: auto !important;
  }
}

.main_play_nd_g {
  height: 35px;
  /* width: 50px; */
}

@media only screen and (max-width: 600px) {
  .main_play_nd_g {
    height: 35px;
    width: 50% !important;
  }
}

/* @font-face {
  font-family: "East Sea Dokdo", sans-serif;
  src: url("../Impact-Font/impact.ttf") format("truetype");
} */
