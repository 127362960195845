@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap');


.main_nav_bar{
    background-color: #064b28;
    color: white !important;
    /* border-bottom: 2px solid white; */
}

.site_font {
    font-family: "Comic Neue", cursive;
  }


.headeder_links{
    color: rgb(255, 255, 255) !important;
    font-size: 20px;
    line-height: 1.5rem;
    /* font-family: "Space Grotesk", sans-serif; */
    font-weight: 500;
}
.navbar-light .navbar-nav .nav-link{
    color: rgb(255, 255, 255) !important;
    font-size: 20px;
    line-height: 1.5rem;
    font-family: "Comic Neue", cursive;
    
    font-weight: 500;
}

.gap_stakw{
    position: relative;
    left: -2rem;
}



.main_header_btn{
    background-color: rgb(43 168 164);
    color: black;
    font-weight: 600;
    font-family: "Comic Neue", cursive;
border: none;
padding: 0.7rem 2rem;
}


.soical_icons{
    color: rgb(255, 255, 255) !important;
    font-size: 30px;
}


.logo_main_width{
    width: 80px !important;
    text-align: left;
}

.logo_main_width img {
    width: 250px;
   
}


/* body{
 background-image: url(../Assets/eth_bg.png
 );

 background-size: cover;
 background-repeat: no-repeat;
 background-attachment: fixed;
} */



@media only screen and (max-width:600px){
    .navbar-light .navbar-nav .nav-link{
        text-align: center !important;
    }

    .tree_btn {
        flex-direction: column !important;
    }
    .gap_stakw{
        position: static !important;
    }
    .logo_main_width img {
        position: static !important;
    }

    .onlyPc{
        margin-left: 0px !important;
    }
}
