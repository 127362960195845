.main_epy_heading-sol {
  color: #ffffff;
  font-family: "Comic Neue", cursive;
  font-size: 44px;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: 0em;
  /* text-align: center; */
}

.address-sol{
    border:2px solid #f98727;
    color:white;
    padding:5px;
    width: 450px;
    border-radius: 20px;
}

.border-image-landing{
  border: 2px solid white;
  transform: rotate(-10deg); /* Adjust the angle as needed */
  display: inline-block;
  height: auto;
  width: 60%
}


.border-image-landing2{
  border: 2px solid white;
  /* transform: rotate(-10deg);  */
  display: inline-block;
  height: auto;
  width: 70%
}


@media only screen and (max-width:760px){
    .address-sol{
        border:2px solid rgb(255, 255, 255);
        color:white;
        padding:5px;
        width: 390px;
        border-radius: 20px;
        font-size: 14px;
    }
    .border-image-landing2{
      border: 2px solid white;
      /* transform: rotate(-10deg);  */
      display: inline-block;
      height: auto;
      width: 100% !important
    }

    .pej-img{
      /* border-radius: 30px; */
      width: 66% !important;
    
    }
  
    .pej-img2{
      /* border-radius: 30px; */
      width: 56% !important;
      margin-left: 60px !important;
  
    }

    .mobile-view{
      text-align: center !important;
    }




}

@media only screen and (max-width:460px){
    .address-sol{
        border:2px solid rgb(255, 255, 255);
        color:white;
        padding:5px;
        width: 300px;
        border-radius: 20px;
        font-size: 10px;
    }
}

/* 
.copy-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;
  } */


  .border-text {
    position: relative;
    display: inline-block;
    padding: 0 15px;
  }
  
  .border-text::before,
  .border-text::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 70px;
    height: 2px;
    background-color: #E9F50B;
    font-weight: 500;
  }
  
  .border-text::before {
    left: -65px;
  }
  
  .border-text::after {
    right: -65px;
  }
  

  .video-container {
    margin-top: 20px;
    display: flex;
    width: 480;
  
    justify-content: center;
    border-radius: 10px;
    border-color: #31c5c9;

  }
  
  .video-center {
    width: 480;
  }
  
  .video-container {
    text-align: center;
  }



  /* Button of website */
  .site-button{
    font-family: "Comic Neue", cursive;
    font-weight: 700;
    fill: #E9FED6;
    color: #E9FED6;
    background-color: #61CE7000;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #E9F50B;
    border-radius: 12px 12px 12px 12px;
    padding: 15px 20px 15px 20px;
    width: 40%;
  }



  .timer_here {
    background: #064B28;
    border-radius: 8px;
    /* border: 1.5px solid #3f0101; */
    padding: 10px;
    padding-top:14px;
  }
  
  .numbers {
    font-family: "Comic Neue", cursive;
    font-size: 27.96px;
    font-weight: 400;
    line-height: 23.47px;
    text-align: center;
    color: #ffffff;
  }
  
  .days {
    font-family: "Comic Neue", cursive;
    color: #ffffff;
    font-size: 13.81px;
    font-weight: 600;
    line-height: 18.84px;
    text-align: center;
  }


  .site-font2{
    font-family: "Comic Neue", cursive;
  }


  .pej-img{
    /* border-radius: 30px; */
    width: 56%;
    height: auto;
    margin-bottom: 20px;
    /* border: 4px solid  #E9FED6 ; */
    /* margin-bottom: 500px; */
  }

  .pej-img2{
    /* border-radius: 30px; */
    width: 39%;
    height: auto;
    margin-bottom: 20px;
    /* border: 4px solid  #E9FED6 ; */
    /* margin-bottom: 500px; */
    margin-left: 30px;
  }

  .pej-img3{
    /* border-radius: 30px; */
    width: 200px;
    /* height: auto; */
    margin-bottom: 20px;
    /* border: 4px solid  #E9FED6 ; */
    /* margin-bottom: 500px; */
    margin-left: 30px;
  }




/* CSS */
.button-32 {
  background-color: #00000000;
  border-radius: 12px;
  color: #ffffff;
  cursor: pointer;
  font-weight: bold;
  padding: 10px 15px;
  text-align: center;
  transition: 200ms;
  width: 100%;
  box-sizing: border-box;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  border: 2px solid #f4e603;
}

.button-32:not(:disabled):hover,
.button-32:not(:disabled):focus {
  outline: 0;
  background: #f4e603;
  box-shadow: 0 0 0 2px rgba(0,0,0,.2), 0 3px 8px 0 rgba(0,0,0,.15);
}

.button-32:disabled {
  filter: saturate(0.2) opacity(0.5);
  -webkit-filter: saturate(0.2) opacity(0.5);
  cursor: not-allowed;
}


.bub-heading{

  font-size: 3.3rem;
  color:#E9F50B;
  font-weight: 700;


}