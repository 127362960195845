.main_buy_eth {
  padding: 4rem 10px;
  /* margin-bottom: 80px; */
  /* background-image: url(../Assets/Glow-down.png); */
  /* background-color: white; */
  background-position: bottom left;
  background-repeat: no-repeat;
  bottom: 20%;
  /* transform: rotate(45deg); */

  background-size: 33%;
}


/* #bdff52 */

.main_heading_buy {
  color: rgb(0, 0, 0);
  font-weight: 700;
  font-size: 3rem;
  /* text-align: center; */
  font-family: GilroyBOLD;
}

.buy_box_eth {
}

.buy_main_box_here {
  padding: 25px 34px;

  height: auto;

  border: 2px solid transparent; /* Set a transparent solid border */
  background-color: #171717;

  border: 2px solid #bdff52;
  border-radius: 10px; /* Updated border radius to 2px */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
  font-family: GilroyBOLD;
}

@media only screen and (max-width: 600px) {
  .buy_main_box_here {
    height: auto;
  }
}


@media only screen and (min-width:1100px){
  .buy_main_box_here{
    height: 350px;
  }

}




.buy_main_box_here h4 {
  color: rgb(229, 229, 229);
  font-size: 26px;
  font-weight: 700;
  font-family: GilroyBOLD;
  text-align: center;
}

.buy_ul {
  color: rgb(229, 229, 229);
  font-size: 18px;
  font-weight: 400;
  font-family: GilroyBOLD;
  text-align: left;
  list-style-type: disc;
}

.site_main_buttn {
  background-color: #2ba8a4;
  color: black;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  justify-content: center;
  border: none;
  padding: 0.75rem 3rem;
  text-transform: uppercase;
}

@media only screen and (max-width: 600px) {
  .main_heading_buy {
    font-size: 2rem;
  }
}

@media (min-width: 768px) {
  .iimage-container {
    width: 30% !important;
  }
}
