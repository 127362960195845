


  .card-type {
    background-color: #064B28;
    padding: 20px;
    border-radius: 30px;
    width:auto;
    color: rgb(255, 255, 255) !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border: 2px solid #E9F50B;
}



/* Add a media query for smaller screens */
@media screen and (max-width: 768px) {
    .card-type {
        padding: 30px; /* Adjust padding for smaller screens */
        border-radius: 20px; /* Adjust border-radius for smaller screens */
    }
}


.card-type1{
  background: linear-gradient(284.41deg, rgb(0, 166, 27) -17.98%, rgb(93, 95, 239) 100.19%), linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03)) transparent;
  border-radius: 30px;
  padding:30px;
}

.presale-text{
  margin-top: 15px;
}

.presale-text h2{
  font-size: 20px;
  color:white;

}

/* CSS */
.button-31 {
  background-color: #222;
  border-radius: 4px;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  /* font-family: "Farfetch Basis","Helvetica Neue",Arial,sans-serif; */
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
  max-width: none;
  min-height: 44px;
  min-width: 10px;
  outline: none;
  overflow: hidden;
  padding: 9px 20px 8px;
  position: relative;
  text-align: center;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
}

.button-31:hover,
.button-31:focus {
  opacity: .75;
}